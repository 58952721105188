import React from 'react';

const NewsContent = () => {
	return (
		<section
			className="elementor-element elementor-element-4e0b365c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
			data-id="4e0b365c"
			data-element_type="section"
		>
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-row">
					<div
						className="elementor-element elementor-element-364bcf19 elementor-column elementor-col-100 elementor-top-column"
						data-id="364bcf19"
						data-element_type="column"
						data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
					>
						<div className="elementor-column-wrap  elementor-element-populated">
							<div className="elementor-widget-wrap">
								<div
									className="elementor-element elementor-element-1d3b41f elementor-widget elementor-widget-text-editor"
									data-id="1d3b41f"
									data-element_type="widget"
									data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
									data-widget_type="text-editor.default"
								>
									{/* <div className="elementor-widget-container">
										<div className="elementor-text-editor elementor-clearfix">
											<p>
												Exercitation photo booth stumptown tote bag Banksy, elit small batch
												freegan sed. Craft beer elit seitan exercitation, photo booth et 8-bit
												kale chips proident chillwave deep v laborum.&nbsp;Aliquip veniam
												delectus, Marfa eiusmod Pinterest&nbsp;in do umami readymade
												swag.&nbsp;Selfies iPhone Kickstarter, drinking vinegar jean vinegar
												stumptown&nbsp;yr pop-up artisan.
											</p>
										</div>
									</div> */}
								</div>
								<div
									className="elementor-element elementor-element-319999f elementor-widget elementor-widget-heading"
									data-id="319999f"
									data-element_type="widget"
									data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
									data-widget_type="heading.default"
								>
									<div className="elementor-widget-container ">
										<h2 className="elementor-heading-title elementor-size-default titleHijau2"
										style={{paddingBottom:'30px'}}>
											Pharma Metric Labs New Milestone
										</h2>
									</div>
								</div>
								<div
									className="elementor-element elementor-element-7a1117b8 elementor-widget elementor-widget-text-editor"
									data-id="7a1117b8"
									data-element_type="widget"
									data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
									data-widget_type="text-editor.default"
								>
									<div className="elementor-widget-container">
										<div className="elementor-text-editor elementor-clearfix">
											<p>
												PT. Pharma Metric Labs (PML) has been announced and certified as one of the accredited Bioavailability/Bioequivalence (BA/BE) Center by National Pharmaceutical Control Bureau (NPCB), Ministry of Health, Malaysia since 2 October 2013. After passing a thorough inspection of Good Clinical Practice (GCP) and Good Laboratory Practice (GLP) compliances, PML is now certified and listed in the NPCB Malaysia Compliance Programme for BA/BE Center.
											</p>
											<p>
												This certification allows PML to continue providing BA/BE service to pharmaceutical industries in Malaysia as well as other pharmaceutical industries intending to market their products in Malaysia. This achievement also serves as confirmation of PML team competence and commitment to quality research.
											</p>
											<p>
												<b>Congratulation for this important new milestone!</b>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		// 		</div>
		// 	</div>
		// </div>
		// <br className="clear" />
		// 	</div>
		// </div>
	);
};

export default NewsContent;
