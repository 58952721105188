import React from 'react';
import ImgComponent from '../../imgComponent';

const HeaderNews = () => {
	return (
		<div
			id="page-header"
			className="hasbg    "
			style={{
				backgroundImage:
					'url(https://drive.google.com/uc?id=1UZS5tse-vkm3PndE9Chef3IjUo1uAImH)'
			}}
		>
			<div id="page-header-overlay">
				<div className="page-title-wrapper">
					<div className="standard-wrapper">
						<div
							className="page-title-inner"
							style={{ opacity: '0.666667', transform: 'translate(0px, -0.5px)' }}
						>
							<div className="page-title-content title_align_left">
								<div className="post-detail single-post smooved" style={{ perspective: '1000px' }}>
									<span
										className="post-info-cat smoove smooved"
										data-move-y="102%"
										style={{ opacity: 1 }}
									>
										{/* <a href="/HAYOKEMANA">LINKSSSSSSSSSSSSSSSSS</a> */}
									</span>
								</div>
								<h1 style={{ perspective: '1000px' }} className="smooved">
									<span className="smoove smooved" data-move-y="102%" style={{ opacity: 1 }}>
										NEWS
									</span>
								</h1>
								{/* <div className="post-attribute smooved" style={{ perspective: '10px' }}>
									<span className="smoove smooved" data-move-y="102%" style={{ opacity: 1 }}>
										By&nbsp;UUUUUUUUUUUUUUUUUUUUUUUUUUUUU
									</span>&nbsp;
									<span className="smoove smooved" data-move-y="102%" style={{ opacity: 1 }}>
										Published On&nbsp;OOOOOOOOOOOOOOOOO
									</span>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeaderNews;
